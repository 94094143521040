<script>
import Base from "@backend/Base.vue";
import draggable from "vuedraggable";
import BOGen from "@helper/BOGen";
import Gen from "@helper/Gen";
export default {
  name: "BoFasilitas",
  components: {
    draggable
  },
  extends: Base,
  data() {
    return {
      Name: "BoFasilitas",
      fasilitas: [],
      fasilitasRaw: {},
      isEdit: false
    }
  },
  mounted() {
    this.$set(this.$root, "page", this);
    this.refreshData();
  },
  methods: {
    submitFasilitas(e) {
      if (e && e.btnLoading()) return;
      if(this.fasilitasRaw.ap_img){
        $("[error='ap_img']").html("");
      }
      if(this.fasilitasRaw.ap_alt_img) {
        $("[error='ap_alt_img']").html("");
      }
      BOGen.apirest(
        "/" + this.Name,
        this.fasilitasRaw,
        (err, resp) => {
          if (e) e.btnUnloading();
          $("[type='submit']").html(this.isEdit ? "Ubah" : "Tambah");
          if (err) {
            if (err.status == 422) {
              Object.keys(err.responseJSON).forEach((k) => {
                var textError = err.responseJSON[k][0];
                if (textError == "validation.required")
                  textError = "Input Required";
                $("[error=" + k + "]").html(
                  "<label class='error'>" + textError + "</label>"
                );
              });
            }
            return;
          }
          if (resp.error)
            return Gen.info(resp.message, "danger", 3000, ".fasilitas-info");
          if (resp.success) {
            Gen.info(resp.message, "success", 2000, ".fasilitas-info");
            this.refreshData();
            setTimeout(() => {
              $(".mt-4.collapse").hide();
            }, 2000);
          }
        },
        "POST"
      );
    },
    addFasilitas() {
      this.isEdit = false;
      $("[type='submit']").html('Tambah');            
      let disp = $("#collapseFasilitas").css("display")
      if(!disp){
        $(".mt-4.collapse").css("display","block")
      }
      $("label.error").css("display","none");
      $("#collapseFasilitas").slideToggle();
      setTimeout(()=>{
        $([document.documentElement, document.body]).animate(
          {
            scrollTop: $("#collapseFasilitas").offset().top,
          },
          500
        );
      },100)
      
      this.fasilitasRaw = {};
      this.fasilitasRaw = {
        ap_is_active: "Y",
      };
      this.fasilitasRaw.type = "add";
    },
    editFasilitas(v) {
      this.isEdit = true;
      $("[type='submit']").html('Ubah');            
      let disp = $("#collapseFasilitas" + v.ap_id).css("display")
      if(!disp){
        $(".mt-4.collapse").css("display","block")
      }

      $("label.error").css("display","none");
      $("#collapseFasilitas" + v.ap_id).slideToggle();
      setTimeout(()=>{
        $([document.documentElement, document.body]).animate(
          {
            scrollTop: $("#collapseFasilitas" + v.ap_id).offset().top,
          },
          500
        );
      },100)

      this.fasilitasRaw = v;
      this.fasilitasRaw.type = "update";
    },
    endDragPrestasi(data) {
      BOGen.apirest(
        "/" + this.Name,
        {
          data: data,
          type: "sortPrestasi",
        },
        (err, resp) => {
          this.refreshData()
        },
        "POST"
      );
    },
  },
  watch: {
    "fasilitasRaw.ap_name"(v) {
      this.fasilitasRaw.ap_alt_img = v;
      $("[name='ap_name']").valid();
      $("[error='ap_alt_img']").html($("[error='ap_name']").html());
    },
    "fasilitasRaw.ap_img"(v) {
      if (v.length > 0) {
        $("[error='ap_img']").html("");
      }
    }
  },
}
</script>
<template>
  <div class="container-fluid">
    <PageTitle></PageTitle>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title">Fasilitas Sekolah</h5>
          </div>
          <div class="card-body">
            <draggable
              v-if="moduleRole('Edit')"
              v-model="fasilitas.data"
              class="row gutter-20"
              @end="endDragPrestasi(fasilitas.data)"
            >
              <div
                style="cursor:move;"
                class="col-md-3"
                :id="'dat' + v.ap_id"
                v-for="(v, k) in fasilitas.data"
                :key="k"
              >
                <div class="wrap_slider_img" style="background-color: #ececec;">
                  <img
                    :src="uploader(v.ap_img)"
                    class="img-responsive"
                    width="100%"
                  />
                  <div class="slider_name">
                    <p>{{ v.ap_name }}</p>
                  </div>
                  <div class="bullet-cta">
                    <a
                      v-if="moduleRole('Edit')"
                      data-toggle="collapse"
                      @click="editFasilitas(v)"
                      href="javascript:;"
                      class="bullet_edit"
                      v-tooltip="'Ubah'"
                      ><i class="ti-marker-alt"></i
                    ></a>
                    <a
                      v-if="moduleRole('Edit')"
                      href="javascript:;"
                      class="bullet_change_status bg-warning"
                      @click="
                        changeStatusDynamic(
                          v,
                          'ap_is_active',
                          'ap_id',
                          'AppPrestasi',
                          undefined,
                          `collapseFasilitas${isEdit ? v.ap_id : ''}`
                        )
                      "
                      v-tooltip="'Ubah Status'"
                      ><i class="ti-settings"></i
                    ></a>
                    <a
                      v-if="moduleRole('Delete')"
                      href="javascript:;"
                      @click="
                        deleteItemCat(v.ap_id, 'dat' + v.ap_id, 'delFasilitas', `collapseFasilitas${isEdit ? v.ap_id : ''}`)
                      "
                      class="bullet_delete"
                      v-tooltip="'Hapus'"
                      ><i class="far fa-trash-alt"></i
                    ></a>
                  </div>
                  <span class="label label-success" v-if="v.ap_is_active == 'Y'"
                    >Active</span
                  >
                  <span class="label label-danger" v-else>Inactive</span>
                </div>
              </div>
              <div class="col-md-3">
                <a
                  v-if="moduleRole('Add')"
                  class="wrap_upload"
                  data-toggle="collapse"
                  @click="addFasilitas"
                  href="#collapseFasilitas"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseFasilitas"
                >
                  <div class="ic_wrap">
                    <i class="fas fa-plus"></i>
                    <p>Tambah Fasilitas</p>
                  </div>
                </a>
              </div>
            </draggable>
            <div
              v-else
              class="row gutter-20"
            >
              <div
                class="col-md-3"
                :id="'dat' + v.ap_id"
                v-for="(v, k) in fasilitas.data"
                :key="k"
              >
                <div class="wrap_slider_img" style="background-color: #ececec;">
                  <img
                    :src="uploader(v.ap_img)"
                    class="img-responsive"
                    width="100%"
                  />
                  <div class="slider_name">
                    <p>{{ v.ap_name }}</p>
                  </div>
                  <div class="bullet-cta">
                    <a
                      v-if="moduleRole('Edit')"
                      data-toggle="collapse"
                      @click="editFasilitas(v)"
                      href="javascript:;"
                      class="bullet_edit"
                      v-tooltip="'Ubah'"
                      ><i class="ti-marker-alt"></i
                    ></a>
                    <a
                      v-if="moduleRole('Edit')"
                      href="javascript:;"
                      class="bullet_change_status bg-warning"
                      @click="
                        changeStatusDynamic(
                          v,
                          'ap_is_active',
                          'ap_id',
                          'AppPrestasi'
                        )
                      "
                      v-tooltip="'Ubah Status'"
                      ><i class="ti-settings"></i
                    ></a>
                    <a
                      v-if="moduleRole('Delete')"
                      href="javascript:;"
                      @click="
                        deleteItemCat(v.ap_id, 'dat' + v.ap_id, 'delFasilitas', 'collapseFasilitas')
                      "
                      class="bullet_delete"
                      v-tooltip="'Hapus'"
                      ><i class="far fa-trash-alt"></i
                    ></a>
                  </div>
                  <span class="label label-success" v-if="v.ap_is_active == 'Y'"
                    >Active</span
                  >
                  <span class="label label-danger" v-else>Inactive</span>
                </div>
              </div>
              <div class="col-md-3">
                <a
                  v-if="moduleRole('Add')"
                  class="wrap_upload"
                  data-toggle="collapse"
                  @click="addFasilitas"
                  href="#collapseFasilitas"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseFasilitas"
                >
                  <div class="ic_wrap">
                    <i class="fas fa-plus"></i>
                    <p>Add New</p>
                  </div>
                </a>
              </div>
            </div>
            <div v-if="isEdit ? moduleRole('Edit') : moduleRole('Add')" class="collapse mt-4" :id="isEdit ?'collapseFasilitas'+fasilitasRaw.ap_id:'collapseFasilitas'">
              <div class="card border">
                <div class="card-header">
                  <h5 class="card-title">
                    {{ (fasilitasRaw.ap_id ? "Ubah" : "Tambah") + " " + ObjectName }}
                  </h5>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="fasilitas-info col-12"></div>
                    <div class="col-md-4 mb-3">
                      <BoField name="ap_img" class="mb-0" mandatory>
                        <Uploader
                          name="ap_img"
                          :param="{ thumbnail: true }"
                          type="eskul"
                          uploadType="cropping"
                          :deleted="false"
                          v-model="fasilitasRaw.ap_img"
                        ></Uploader>
                      </BoField>

                      <BoField
                        mandatory
                        name="ap_alt_img"
                        v-model="fasilitasRaw.ap_alt_img"
                        :attr="{
                          type: 'text',
                          placeholder: 'e.g. Tempat Parkir'
                        }"
                        :label="'Alt Image'"
                      ></BoField>
                    </div>
                    <div class="col-md-8 mb-3">
                      <VForm @resp="submitFasilitas" method="post">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="panel_group">
                              <div class="panel_heading">Fasilitas</div>
                              <div class="panel_body">
                                <div class="row">
                                  <div class="col-12 col-info"></div>
                                  <div class="col-md-6">
                                    <BoField
                                      name="ap_name"
                                      :attr="{
                                        maxlength: '100',
                                        minlength: '3',
                                        required: 'required',
                                        unique: 'unique',
                                        placeholder: 'e.g. Tempat Parkir'
                                      }"
                                      :label="'Nama Fasilitas'"
                                      v-model="fasilitasRaw.ap_name"
                                      mandatory
                                    ></BoField>
                                  </div>
                                  <div class="col-sm-4">
                                    <BoField name="ap_is_active">
                                      <radio
                                        name="ap_is_active"
                                        v-model="fasilitasRaw.ap_is_active"
                                        option="Y"
                                        :attr="validation('ap_is_active')"
                                        >Active</radio
                                      >
                                      <radio
                                        name="ap_is_active"
                                        v-model="fasilitasRaw.ap_is_active"
                                        option="N"
                                        >Inactive
                                      </radio>
                                    </BoField>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 mt-3 text-right">
                            <button
                              type="submit"
                              class="btn btn-rounded btn-loading btn-info"
                            >
                              {{isEdit ? 'Ubah' : 'Tambah'}}
                            </button>
                          </div>
                        </div>
                      </VForm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>